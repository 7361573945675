import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './App.css';
import { RedocStandalone } from 'redoc';

const END_POINT = process.env.REACT_APP_API_URL;
const ADMIN_END_POINT = process.env.REACT_APP_ADMIN_URL;
const HOME_END_POINT = process.env.REACT_APP_HOME_URL;
const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN;
const COOKIE_NAME = '_at_docs_sess';

const addMessageEventListener = (setIsSuccess) => {
    window.addEventListener('message', (event) => {
        if (event.origin !== ADMIN_END_POINT && event.origin !== HOME_END_POINT) {
            return;
        }
        setIsSuccess(true);
        Cookies.set(COOKIE_NAME, event.data, {domain: COOKIE_DOMAIN});
    });
}

const requestLogin = (setIsSuccess) => {
    alert("Please log in first.")
    window.open(HOME_END_POINT + '/docs/login');
    addMessageEventListener(setIsSuccess);
}

const getJwt = (setIsSuccess) => {
    addMessageEventListener(setIsSuccess);
    if (document.referrer) {
        window.opener.postMessage('READY_TO_CHILD_WINDOW', document.referrer);
    }
}

const getSpec = (setSpec, setIsSuccess) => {
    axios.get(END_POINT + "/spec/json", {withCredentials: true})
        .then(res => {
            let spec = res.data;
            spec['x-tagGroups'] = spec['extensions']['x-tagGroups'];
            spec['info']['x-logo'] = spec['info']['extensions']['x-logo'];
            setSpec(spec);
        })
        .catch(error => {
            console.error(error);
            requestLogin(setIsSuccess);
        });
}
function App() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [spec, setSpec] = useState();

    useEffect(() => {
        if (window.opener) {
            getJwt(setIsSuccess);
        }
        else if (!Cookies.get(COOKIE_NAME)) {
            requestLogin(setIsSuccess);
        }
    }, []);

    useEffect(() => {
        if ((window.opener && isSuccess) || Cookies.get(COOKIE_NAME)) {
            getSpec(setSpec, setIsSuccess);
        }
    }, [isSuccess]);

    return (
    <div className="App">
        {spec && (<RedocStandalone spec={spec} options={{jsonSampleExpandLevel: 'all'}}/>)}
    </div>
  );
}

export default App;
